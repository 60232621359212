import Vue, {nextTick} from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/pages/shop-manage',
  },
  {
    path: '/pages',
    name: 'Pages',
    redirect: '/pages/violation',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '/pages/violation',
        name: 'Violation',
        component: () => import('@/views/violation/index.vue'),
        meta: {
          title: '违规检测'
        },

      },
      // {
      //   path: '/pages/sangle-picture-search',
      //   name: 'SanglePictureSearch',
      //   component: () => import('@/views/sangle-picture-search/index.vue'),
      //   meta: {
      //     title: '违规检测'
      //   }
      // },
      {
        path: '/pages/shop-manage',
        name: 'shopManage',
        component: () => import('@/views/shopManage/index.vue'),
        meta: {
          title: '店铺管理'
        },
        beforeEnter: (to, from, next) => {
          const info = JSON.parse(localStorage.getItem('login_param'))
          if (info) {
            next()
          } else {
            router.push('/login')
          }

        }
      },
      // {
      //   path: '/pages/image-search-same',
      //   name: 'imageSearchSame',
      //   component: () => import('@/views/imageSearchSame/index.vue'),
      //   meta: {
      //     title: '图搜同行'
      //   }
      // },{
      //   path: '/pages/image-search',
      //   name: 'imageSearch',
      //   component: () => import('@/views/imageSearch/index.vue'),
      //   meta: {
      //     title: '图搜货源地'
      //   }
      // },
      {
        path: '/pages/example',
        name: 'example',
        component: () => import('@/views/example/example.vue'),
        meta: {
          title: '我的案例'
        }
      },
    ]
  },

//   {
//     path: '/image-search',
//     name: 'imageSearch',
//     component: () => import('@/views/imageSearch/index.vue'),
//     meta: {
//       title: '图搜工具'
//   }
//   },


  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy/index.vue'),
    meta: {
      title: '隐私协议'
    }
  },
//   {
//     path: '/doudianKeyWords',
//     name: 'doudianKeyWords',
//     component: () => import('@/views/keyWordsCollection/index.vue'),
//     meta: {
//       title: '抖店关键词采集'
//   }
//   },
//   {
//     path: '/doudianLink',
//     name: 'doudianLink',
//     component: () => import('@/views/linkCollection/index.vue'),
//     meta: {
//       title: '抖店链接采集'
//   }
//   },
//   {
//     path: '/doudianStore',
//     name: 'doudianStore',
//     component: () => import('@/views/shopCollection/index.vue'),
//     meta: {
//       title: '抖店整店采集'
//   }
//   },
//   {
//     path: '/doudianGoods',
//     name: 'doudianGoods',
//     component: () => import('@/views/doudianRelease/index.vue'),
//     meta: {
//       title: '抖店商品'
//   }
//   },
//   {
//     path: '/doudianGoods-edit',
//     component: () => import( /* webpackChunkName: "donate" */ '@/views/doudianRelease/doudianGoods-edit.vue'),
//     meta: {
//         title: '属性编辑'
//     }
// },{
//   path: '/prod-manage',
//   component: () => import( /* webpackChunkName: "donate" */ '@/views/prodManage/index.vue'),
//   meta: {
//       title: '产品管理'
//   }
// },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
