<template>
  <div class="sidebar">
    <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#fff"
      text-color="#000" active-text-color="#ff7900" unique-opened router>
      <template v-for="item in getItems">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <!-- <i :class="item.icon"></i> -->
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                <template slot="title">{{ subItem.title }}</template>
                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">{{
                  threeItem.title
                }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}</el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <!-- <i :class="item.icon"></i> -->
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>


    <div class="contain" style="    position: absolute;
    bottom: 20px;
    left: 25px;
">
<!--      <div class="qr">-->
<!--        <img style="width: 75px;height: 75px;" :src="applicationInfo.wechatImage" alt="">-->
<!--      </div>-->
      <div style="margin-bottom: 10px;text-align: center;font-size: 20px;cursor: pointer;color: #e6a23c" @click="inBackMethod">进入后台</div>
    <div style="margin-bottom: 10px;text-align: center">{{applicationInfo.people_phone}}</div>
      <el-button size="small" type="" @click="logoutMethod()">退出登录</el-button>
    </div>
  </div>
</template>

<script>
import bus from './bus';

export default {
  data() {
    return {
      collapse: false,
      items: [
        // {
        //   icon: 'el-icon-lx-home',
        //   index: 'dashboard',
        //   title: '系统首页'
        // },
        {
          icon: 'el-icon-lx-home',
          index: 'shop-manage',
          title: '店铺管理'
        },
        {
          icon: 'el-icon-lx-home',
          index: 'violation',
          title: '数据记录'
        },
      ]
    };
  },
  computed: {

    getItems() {
      // '发布宝贝', '产品管理'
      // const filterList = ['抖店采集','发布宝贝', '产品管理', '图搜工具', '店铺管理']
      // return this.items.filter(it => filterList.includes(it.title))
      return this.items
    },
    onRoutes() {
      console.log(this.$route.path.replace('/page/', ''), '00000000000000000')
      return this.$route.path.replace('/pages/', '');
    }
  },
  created() {
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    bus.$on('collapse', (msg) => {
      this.collapse = msg;
      bus.$emit('collapse-content', msg);
    });
    this.applicationInfo =JSON.parse(localStorage.getItem('login_param'))
    console.log(this.applicationInfo)
  },methods:{
    inBackMethod(){
      const url = "https://miaowuzhenxuan.com";
      // alert(url)
      this.$request('open_waibu_url',{"url":url});
    },
    logoutMethod(){
      this.$confirm('确定退出吗？', '提示', {
        type: 'warning'
      })
          .then(() => {
            localStorage.removeItem("login_param")
            localStorage.removeItem("token")
            localStorage.removeItem("userInfo")
            this.$router.push('/login');
          })
          .catch(() => {});

    }
  }
};
</script>

<style scoped lang="scss">
.sidebar {
  height: 100vh;
  display: block;
  overflow-y: scroll;
  position: relative;

  ::v-deep .el-menu.sidebar-el-menu{
    &>.el-menu-item, .el-submenu__title{
      padding: 0 0 0 16px!important;
      min-width: auto;
      height: 42px;
      line-height: 42px;
    }
    .el-submenu .el-menu-item{
      padding: 0 0 0 20px!important;
      min-width: auto;
      height: 36px;
      line-height: 36px;
      font-size: 13px;
    }
  }

}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 12rem;
}

.sidebar>ul {
  height: 100%;
}
</style>
