import Vue from 'vue'
import {QWebChannel} from './qwebchannel'
import {Message, Notification} from 'element-ui'
import store from '../store/index'

export var callBackDict = {}

export var qtWebChannel = null // 导出qtWebChannel，供其他页面调用
new QWebChannel(qt.webChannelTransport, (channel) => {
    // all published objects are available in channel.objects under
    // the identifier set in their attached WebChannel.id property
    qtWebChannel = channel.objects.bridge
    Vue.prototype.$QtCallBackDict = callBackDict
    Vue.prototype.$QtWebChannel = qtWebChannel
    Vue.prototype.$request = request

    console.log(1,qtWebChannel)
    ChannelCallBack()
    // 进入回调地狱

})
function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
}
Vue.prototype.$uuid = guid;
Vue.prototype.$shopRadio = null;
function request(func, data = null) {

    var allow_list =[
        'pduoduo_get_cate_list',
        'login_shop',
        'login_out',
        'CLOSE_WINDOW',
        'OPEN_WINDOW',
        'GET_MODEL',
        'open_waibu_url'
    ]
    // console.log('5555555')
    // if(allow_list.indexOf(func)!==-1){
        console.log('555555577777777')
        Vue.prototype.$QtWebChannel.request(
            JSON.stringify({func: func, data: data})
        )
        console.log('555555588888')
    // }else{
    //     Message({
    //         message: '您还不是Vip,请先去充值续费',
    //         type: 'error',
    //         duration: 2 * 1000
    //     });
    //
    // }

}

// 回调地狱
function ChannelCallBack() {
    console.log('初始化')
    qtWebChannel.connectSignal.connect(connectSignalCallBack)
}

function connectSignalCallBack(jsonString) {
    /*
    res: {
        code: 200/400 400就是后台有问题
        func: string 在mount中载入
        data: Dict 传入到view函数中
    }
    */
    // console.info(callBackDict)
    const res = JSON.parse(jsonString)
    // 根据code码 想要什么信息提示框就自己整合
    console.log(23456,res)
    switch (res.code) {
        case 200:
            const func = res.data.func;
            const data = res.data.data;
            callBackDict[func](data);
            return;
        case 400:
            Message({
                message: res.message || 'Error',
                type: 'error',
                duration: 2 * 1000
            });
            return;
        case 201:
            Notification({
                type: 'success',
                title: 'INFO',
                message: res.message || "Success",
                duration: 2 * 1000
            });
            return;
    }
}
