import axios from 'axios';

const service = axios.create({
    timeout: 50000,
});


service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log(error);

        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data;
        } else {
            return Promise.reject();
            // return false
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

export default service;
