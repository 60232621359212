import request from '../utils/request';
// export const host="http://192.168.1.18:12345/api/v1";
// export const host="http://120.55.114.181:5000/api/v1";
export const host="https://api.miaowuzhenxuan.com/api/v1";
// export const host="https://duoduojilu.yuduoduogongju.com:8001/api/v1";
// export const host="https://api.htapp.fun";

export const people_login = query => {
    return request({
        url: host + '/other/people_login',
        method: 'post',
        data: query
    });
};
// http://192.168.1.18:5000/api/v1/other/people_shop_list
export const other_people_shop_list = query => {
    return request({
        url: host + '/other/people_shop_list',
        method: 'get',
        params: query
    });
};

export const people_people_data_list = query => {
    return request({
        url: host + '/other/people_data_list',
        method: 'get',
        params: query
    });
};
// http://192.168.1.18:12345/api/v1/other/license
export const other_license = query => {
    return request({
        url: host + '/other/license',
        method: 'get',
        params: query
    });
};

export const other_importExcel = query => {
    return request({
        url: host + '/other/importExcel',
        method: 'post',
        data: query
    });
};
