<template>
  <div id="app">
<!--   <layout />-->
    <router-view />
  </div>
</template>

<script>
import layout from './layout'
export default {
  components: { layout },
  mounted() {
    document.querySelector('#loading-container').style.display = 'none'
  }
}
</script>

<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
}
</style>
