<template>
    <div class="wrapper">

        <v-sidebar></v-sidebar>
        <div class="content-box" :class="{'content-collapse':collapse}">

            <div class="content">
                <transition name="move" mode="out-in">
                    <keep-alive >
                        <router-view></router-view>
                    </keep-alive>
                </transition>
                <el-backtop target=".content"></el-backtop>
            </div>
        </div>
<!--      <div-->
<!--          v-if="text"-->
<!--          style="-->
<!--          width: 500px;-->
<!--          position: absolute;-->
<!--          bottom: 0;-->
<!--          left: 50%;-->
<!--          transform: translateX(-50%);-->
<!--          font-size: 14px;-->
<!--          display: flex;-->
<!--          align-items: center;-->
<!--        ">-->
<!--        <img style="width: 20px;height: 20px;" src="@/assets/laba.gif" alt="">-->
<!--        <span style="width: 40px;">公告:</span>-->
<!--        <div style="width: 400px;height: 20px;margin-left: 5px;" class="scroll-container">-->
<!--         <div :class="['text', 'text-scroll' ]">-->
<!--         {{ text }}-->
<!--         </div>-->
<!--        </div>-->

<!--      </div>-->
    </div>
</template>

<script>
// import vHead from './Header.vue';
import vSidebar from './Sidebar.vue';
import vTags from './Tags.vue';
import bus from './bus';
import {applicationGongGao, applicationTongZhi, applicationTongZhiSee} from "@/api";
export default {
    data() {
        return {
          text: '',
            tagsList: [],
            collapse: false
        };
    },
    components: {

        vSidebar,
        vTags
    },
    created() {
        bus.$on('collapse-content', msg => {
            this.collapse = msg;
        });

        // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
        bus.$on('tags', msg => {
            let arr = [];
            for (let i = 0, len = msg.length; i < len; i++) {
                msg[i].name && arr.push(msg[i].name);
            }
            this.tagsList = arr;
        });
      // this.getInfo()
    },
  methods: {
      getInfo() {
        applicationGongGao().then(res=> {
          try {
            this.text = res.data.res[0]?.content
          } catch(e) {
            this.text = ''
          }
        })
        applicationTongZhi().then(res => {
          try {
            if(!res.data.res[0]?.is_visit){
              this.$notify({
                dangerouslyUseHTMLString:true,
                title:   res.data.res[0]?.title,
                duration: 0,
                position: 'bottom-right',
                message:    res.data.res[0]?.content
              });
              applicationTongZhiSee({id:res.data.res[0]?.id}).then(res=>{
                console.log(res)
              })
            }

          } catch(e) {
          }

        })
        setTimeout(this.getInfo, 1*60*1000)
      }
  }
};
</script>

<style scoped lang="scss">
.wrapper {
    width: 100%;
    height: 100%;
    //min-width: 1000px;
    display: flex;
    .content-box {
      flex: 1;
        //width: calc(100vw - 12rem);
        //min-width: calc(1000px - 12rem);
        height: 100%;
        overflow: hidden;
        .content {
            height: 100%;
            width: 100%;
        }
    }
}

.scroll-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  line-height: 20px;
}

.text {
  position: absolute;
  top: 0;
  height: 24px;
  z-index: 999;
  animation: scroll-left 7s linear infinite;
}

@keyframes scroll-left {
  0% {
    left: 400px;

  }
  100% {
    left: -100%;
  }
}

.text-scroll {
  animation-play-state: running;
}
</style>
