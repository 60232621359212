import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shopRadio: null,
    releaseProd: null,
    selectedGoods: []
  },
  getters: {
  },
  mutations: {
    SET_A (state, val) {
      state.shopRadio = val
    },
    SET_RELEASE_PROD(state, val) {
      state.releaseProd = val
    },
    CLEAR_RELEASE_PROD(state, val) {
      state.releaseProd = null
    },
    REMOVE_RELEASE_PROD(state, key, val) {
      delete state.releaseProd[key]
    },
    SET_SELECTED_GOODS(state, key) {
      state.selectedGoods = null
    },
  },
  actions: {
  },
  modules: {
  }
})
